import { Link } from "react-router-dom";
import Animate from "./Animate";

export default function Article(props) {

  function containsArabic(content) {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(content);
  }

  return (
    <div
      className={
        "libraries adjust-flex d-lg-flex col-lg-4 col-md-6 align-items-center d-flex position-relative px-3 py-5 " +
        (props.index % 2 ? " black" : " ")
      }
    >
      {props.id === 1 && (
        <img
          className="e-library-bg"
          src="/assets/images/fixed-images/e-library.svg"
          alt="background"
        />
      )}

      {props.id === 3 && (
        <>
          <img
            className="e-library-bg-2 d-lg-block d-none"
            src="/assets/images/fixed-images/elibrary-bg.svg"
            alt="background"
          />
          <img
            className="e-library-bg-3 d-lg-block d-none"
            src="/assets/images/fixed-images/elibrary-background.svg"
            alt="background"
          />
        </>
      )}

      {(!props.download_file && props.single_page) || (props.download_file && props.single_page) ? (
        <Link className="w-100" to={"/e-library/" + props.slug}>
          <Animate animate="left">
            <div className={"row fix-padding-mobile "}>
              <div className="col">
                <div className="article position-relative">
                  <div className="image-article">
                    <h2 className={"flex-grow-1 h-100 mb-3 " + (containsArabic(props.title) && 'arabic-rtl' )}>{props.title}</h2>
                    <div className="ratio library-ratio shadow">
                      <img src={props.image} alt="article" />
                    </div>
                  </div>
                  <div className="show-on-hover e-lib">
                    <p className={"mb-0 mt-3 " + (containsArabic(props.paragraph) &&  ' arabic-rtl' )}>{props.paragraph}</p>
                    <div className="pt-3 mb-1">
                        <button className="button yellow-button">
                          {props.button}
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Animate>
        </Link>
      ) : (
        <a
          className="w-100"
          href={props.full_path_download_file}
          download
          target="_blank"
          rel="noreferrer"
                  >
          <Animate animate="left">
            <div className="row fix-padding-mobile">
              <div className="col">
                <div className="article position-relative">
                  <div className="image-article">
                    <h2 className="flex-grow-1 h-100 mb-3">{props.title}</h2>
                    <div className="ratio library-ratio shadow">
                      <img src={props.image} alt="article" />
                    </div>
                  </div>
                  <div className="show-on-hover">
                    <p className="mb-0 mt-3">{props.paragraph}</p>
                    <div className="pt-3 mb-1">
                    
                        <button className="button yellow-button">
                          {props.button}
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Animate>
        </a>
      )}
    </div>
  );
}
