import Animate from "../components/Animate";
import Banner from "../components/Banner";
import EventsComponent from "../components/EventsComponent";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import Api from "../components/api";
import { useContext, useEffect, useState } from "react";
import ContactPopup from "../components/ContactPopup";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";


import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";

export default function UpcomingEvents() {
const navigate = useNavigate();

  let { slug } = useParams();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [singlePageData, setSinglePageData] = useState();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState();
  const { generalDataGlobal } = useContext(GlobalState);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [apiOptions, setApiOptions] = useState();

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "events/" + slug,
      callback: (res) => {
        setSinglePageData(res.data);
      },catch404: () =>{
        navigate("/404");
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const contactUs = (e) => {
    e.preventDefault();
    setApiOptions({
      url: "/register-event",
      method: "post",
      data: {
        'name': name,
        'email': email,
        'phone': phone,
        'id': singlePageData.id
      },
      callback: (r) => {
        setName("");
        setEmail("");
        setPhone("");
        setErrorPopup(false);
        setSuccessPopup(true);
        setTimeout(() => {
          setSuccessPopup(false);
        }, 3000);
      },
      catch422: (errors) => {
        setErrorMessages(errors)
        setSuccessPopup(false);
        setErrorPopup(true);
      },
    });
  };

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={apiOptions} />

      <Layout activePage="events">
      <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages.events.title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages.events.image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages.events.image}
            />
          </Helmet>
        <Banner
          title="Upcoming Event"
          banner={singlePageData?.full_path_image}
        />

        <EventsComponent data={singlePageData} />

        <div className="d-lg-flex align-items-center d-flex position-relative pb-5 overflow-hidden ">
          <div className="custom-container w-100">
            <form className="" onSubmit={contactUs}>
              <Animate animate="left">
                <h2 className="general-title mb-4">
                  {generalDataGlobal[0].events_settings.register_title}
                </h2>
                <div className="pb-4">
                  <input
                    className="form"
                    type="text"
                    placeholder={generalDataGlobal[0].events_settings.name_placeholder}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="pb-4">
                  <input
                    className="form"
                    type="text"
                    placeholder={generalDataGlobal[0].events_settings.email_placeholder}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="pb-4">
                  <input
                    className="form"
                    type="number"
                    placeholder={generalDataGlobal[0].events_settings.phone_placeholder}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="pt-4">
                  <button type="submit" className="button black-button">
                    {generalDataGlobal[0].events_settings.register_button}
                  </button>
                </div>
              </Animate>
            </form>
          </div>
        </div>

        <Popup
          isOpen={successPopup}
          close={() => setSuccessPopup(false)}
          modalWindowClass="col-lg-5 col-md-9 col-sm-10 col-11"
        >
          <ContactPopup
            image={generalDataGlobal[0]?.events_settings.success_image}
            title={generalDataGlobal[0]?.events_settings.success_title}
            subtitle={generalDataGlobal[0]?.events_settings.success_message}
          />
        </Popup>

        <Popup 
                isOpen={errorPopup}
                close={() => setErrorPopup(false)}
                modalWindowClass="col-lg-5 col-md-9 col-sm-10 col-11"
            >
                <ContactPopup
                    image={generalDataGlobal[0]?.events_settings.error_image}
                    title={generalDataGlobal[0]?.events_settings.error_title}
                    subtitle="Sorry Something Went Wrong !"
                    button="TRY AGAIN"
                    errors={errorMessages}
                />
            </Popup>
      </Layout>
    </>
  );
}
