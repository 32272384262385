import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./pages/404";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import ELibrary from "./pages/ELibrary";
import ELibrarySinglePage from "./pages/ELibrarySinglePage";
import Events from "./pages/Events";
import Home from "./pages/Home";
import OurLodge from "./pages/OurLodge";
import PreviousEvents from "./pages/PreviousEvents";
import UpcomingEvents from "./pages/UpcomingEvents";
import axios from "axios";
import { useEffect, useState } from "react";
import GlobalState from "./contexts/GlobalState";
import Api from "./components/api";

function App() {
  
  const axiosBaseUrl = "https://glnl.org/api/";
  // const axiosBaseUrl = "https://www.acacia1.org/api/";
  // const axiosBaseUrl = "http://127.0.0.1:8000/api/"
  axios.defaults.baseURL = axiosBaseUrl;
  const [generalData, setGeneralData] = useState();
  const [menuItems, setMenuItems] = useState();
  const [socialIcons, setSocialIcons] = useState();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "general",
      callback: (res) => {
        setGeneralData(res.data);

      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  

  return (
    <>
      <Api options={generalDataApiOptions} />
      {!generalData ? null : 
      <GlobalState.Provider value={{
        generalDataGlobal: [generalData, setGeneralData],
        menuItemsGlobal: [menuItems, setMenuItems],
        socialIconsGlobal: [socialIcons, setSocialIcons],
      }}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route exact path="/previous-events/:slug" element={<PreviousEvents />} />
            <Route exact path="/upcoming-events/:slug" element={<UpcomingEvents />} />
            <Route path="/previous-events/*" element={<PreviousEvents />} />

            {/* <Route path="/upcoming-events" element={<UpcomingEvents />} /> */}
            <Route path="/events" element={<Events />} />
            <Route path="/our-lodge" element={<OurLodge />} />
            <Route exact path="/e-library" element={<ELibrary />} />
            <Route exact path="/e-library/:slug" element={<ELibrarySinglePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
        </GlobalState.Provider>
      }
    </>
  );
}

export default App;
