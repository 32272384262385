export default function ContactPopup(props) {
        const errors = [];

        for (const key in props.errors) {

            errors.push(props.errors[key])
        }
    return (

        <div className="white-popup p-sm-5 p-4">
            <div className="text-center">
                <div className=" mb-4">
                    <img src={props.image} alt="success" />
                </div>
                <div className=" ">
                    <div className="flex-column d-flex h-100">
                        <h4 className="mb-2">{props.title}</h4>
                        
                        { props.errors ? 
                            errors.map((err, index) => (
                                
                                <p key={index}>{err}</p>
                            )) : 
                            <p>{props.subtitle}</p>
                        }


                    </div>
                </div>
            </div>
        </div>

    )
}