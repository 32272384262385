export default function IconsHomepage(props) {
    return (
        // <div className="homepage-icons position-relative">
        //     <div className="">
        //         <div className="icon-text">
        //             <div className="icon text-center">
        //                 <img className="icon-home pb-3" src={props.firstIcon} alt={props.title} />
        //             </div>
        //             <div className="d-flex title-icon align-items-center pb-4">
        //                 <img className="icon-home-hover" src={props.secondIcon} alt={props.title} />
        //                 <h2 className="mb-0">{props.title}</h2>
        //             </div>
        //         </div>
        //         <div className="row subtitle">
        //             <div className="col-lg-12 col-md-10 col-sm-8">
        //                 <p className="m-0">{props.subtitle}</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>


        <div className="homepage-icons ">
            <div className="">
                <div className="icon-text">
                    <div className="icon-home position-relative">
                        <img className="" src={props.firstIcon} alt={props.title} />
                    </div>
                    <div className="title-icon">
                        <h2 className="">{props.title}</h2>
                    </div>
                </div>
                <div className="row subtitle justify-content-sm-center justify-content-center">
                    <div className="col-lg-12 col-md-10 col-sm-8">
                        <p className="">{props.subtitle}</p>
                    </div>
                </div>
            </div>
        </div>
    )
} 