import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import Stars from "./Stars";

export default function Layout(props) {
  // const desktopWidth = 991.98;
  const { generalDataGlobal } = useContext(GlobalState);

  const [sideMenu, setSideMenu] = useState(false);


  // function isDesktop() {
  //   return window.innerWidth > desktopWidth;
  // }

  function sideMenuClick() {
    setSideMenu(true);
  }

  function onScrollContainerScroll(e) {
    if (props.activePage === "home") {
      var switchPos = window.innerHeight / 2;
      var whiteSections = document.querySelectorAll(".js-white-section");
      var isBlackBg = true;
      for (let i = 0; i < whiteSections.length; i++) {
        var sectionStart = whiteSections[i].getBoundingClientRect().y;
        var sectionEnd = whiteSections[i].getBoundingClientRect().y * -1;
        if (sectionStart < switchPos && sectionEnd < switchPos) {
          isBlackBg = false;
        }
      }

      if (!isBlackBg)
        document.querySelector(".scroll-container").classList.add("white-bg");
      else
        document
          .querySelector(".scroll-container")
          .classList.remove("white-bg");
    };
  }


  // let initialLoadIsDesktop = isDesktop();

  useEffect(() => {


    document.querySelector('.scroll-container').addEventListener('scroll', window.triggerScroll);

    if (props.activePage === "home") {
      window.onscroll = function () {
        var switchPos = window.innerHeight / 2;
        var whiteSections = document.querySelectorAll(".js-white-section");
        var isBlackBg = true;
        for (let i = 0; i < whiteSections.length; i++) {
          var sectionStart = whiteSections[i].getBoundingClientRect().y;
          var sectionEnd = whiteSections[i].getBoundingClientRect().y * -1;
          if (sectionStart < switchPos && sectionEnd < switchPos) {
            isBlackBg = false;
          }
        }

        if (!isBlackBg)
          document.querySelector(".scroll-container").classList.add("white-bg");
        else
          document
            .querySelector(".scroll-container")
            .classList.remove("white-bg");
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div
        className={
          "fixed-top custom-navbar shadow px-3 py-lg-5" +
          (props.blackNav ? " black-nav" : " ")
        }
      >
        <div className="d-flex flex-lg-column h-100  align-items-center">
          <div
            className="flex-1 d-lg-flex align-self-center burger-icon order-lg-1 order-2 text-lg-start text-end"
            onClick={sideMenuClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="19.5"
              viewBox="0 0 33 19.5"
            >
              <g
                id="Group_3190"
                data-name="Group 3190"
                transform="translate(-16.5 -46.5)"
              >
                <line
                  className={props.blackNav ? " burger-stroke" : " "}
                  id="Line_2"
                  data-name="Line 2"
                  x2="30"
                  transform="translate(18 48)"
                  fill="none"
                  stroke="#2b2b2b"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  className={props.blackNav ? " burger-stroke" : " "}
                  id="Line_3"
                  data-name="Line 3"
                  x2="30"
                  transform="translate(18 64.5)"
                  fill="none"
                  stroke="#2b2b2b"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
                <line
                  className={props.blackNav ? " burger-stroke" : " "}
                  id="Line_88"
                  data-name="Line 88"
                  x2="30"
                  transform="translate(18 56.5)"
                  fill="none"
                  stroke="#2b2b2b"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </g>
            </svg>
          </div>
          <div className="flex-1 d-lg-flex align-items-center justify-content-center order-lg-2 order-1">
            <Link to="/">
              <img
                className="logo px-lg-2 py-md-4 py-lg-0 py-2"
                src={generalDataGlobal[0]?.fixed_settings?.main_logo}
                alt="logo"
              />
            </Link>
          </div>
          <div className="flex-1 d-lg-flex d-none order-lg-3 order-2"></div>
        </div>
      </div>

      <div
        className={
          "scroll-container" +
          (props.activePage === "home" ? " home-background-color" : " ") +
          (props.blackNav ? " white-bg" : "")
        }
        onScroll={onScrollContainerScroll}
      >
        {
          props.activePage !== "e-library" ?
            <Stars />
            :
            null

        }
        {props.children}

        <div className={props.activePage === "about-us" ? "give-bg-color" : ""}>
          <div
            className={
              "footer position-relative d-flex flex-column justify-content-center h-100 py-4" +
              (props.blackNav ? " footer-black" : " ")
            }
          >
            <img
              className="footer-bg"
              src="/assets/images/fixed-images/result.svg"
              alt=""
            />
            <div className="text-center pb-4 menu-links-footer">
              <Link to="/">
                <img
                  className="logo-footer"
                  src={generalDataGlobal[0]?.fixed_settings?.main_logo}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                className="mx-2"
                src="/assets/images/fixed-images/line.svg"
                alt=""
              />
              <p className="mb-0">{generalDataGlobal[0].menu_items["footer-text"]}</p>
              <img
                className="mx-2"
                src="/assets/images/fixed-images/line.svg"
                alt=""
              />
            </div>
            <div className="container-fluid">
              <div className="row pt-4 justify-content-center text-center menu-links-footer">
                <div className="col-5 mb-3">
                  <Link
                    to="/about-us"
                    className={
                      "footer-link" +
                      (props.activePage === "about-us" ? " active" : " ")
                    }
                  >
                    {generalDataGlobal[0].menu_items["about-us"]}
                  </Link>
                </div>
                <div className="col-5 mb-3">
                  <Link
                    to="/our-lodge"
                    className={
                      "footer-link" +
                      (props.activePage === "our-lodge" ? " active" : " ")
                    }
                  >
                    {generalDataGlobal[0].menu_items["our-lodge"]}
                  </Link>
                </div>
                <div className="col-5 mb-3">
                  <Link
                    to="/e-library"
                    className={
                      "footer-link" +
                      (props.activePage === "e-library" ? " active" : " ")
                    }
                  >
                    {generalDataGlobal[0].menu_items["e-library"]}
                  </Link>
                </div>
                <div className="col-5 mb-3">
                  <Link
                    to="/contact-us"
                    className={
                      "footer-link" +
                      (props.activePage === "contact-us" ? " active" : " ")
                    }
                  >
                    {generalDataGlobal[0].menu_items["contact-us"]}
                  </Link>
                </div>
              </div>
              <div className="row justify-content-center mt-1 gx-2 social-media-footer">
                <h2 className="text-center mb-3">
                  {generalDataGlobal[0].menu_items["follow-us"]}
                </h2>

                {generalDataGlobal[0].social_media.map((item, index) => (
                  <div key={index} className="col-auto">
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={
                          "social-icon" + (props.blackNav ? " invert" : " ")
                        }
                        src={item.icon}
                        alt="social"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={" side-menu" + (sideMenu ? " " : " fade-out")}>
        <div
          className=" close-side-menu position-absolute flex-1 d-flex align-self-center d-flex pt-lg-5 ms-lg-4"
          onClick={() => setSideMenu(false)}
        >
          <img
            className=""
            src="/assets/images/fixed-images/close-side-menu.svg"
            alt="close"
          />
        </div>
        <div className="background-menu d-flex flex-column justify-content-center h-100">
          <div className="text-center">
            <Link
              to="/about-us"
              className={
                "menu-link" +
                (props.activePage === "about-us" ? " active" : " ")
              }
            >
              {generalDataGlobal[0].menu_items["about-us"]}
            </Link>
          </div>
          <div className="text-center">
            <Link
              to="/our-lodge"
              className={
                "menu-link" +
                (props.activePage === "our-lodge" ? " active" : " ")
              }
            >
              {generalDataGlobal[0].menu_items["our-lodge"]}
            </Link>
          </div>
          <div className="text-center">
            <Link
              to="/e-library"
              className={
                "menu-link" +
                (props.activePage === "e-library" ? " active" : " ")
              }
            >
              {generalDataGlobal[0].menu_items["e-library"]}
            </Link>
          </div>
          <div className="text-center">
            <Link
              to="/events"
              className={
                "menu-link" + (props.activePage === "events" ? " active" : " ")
              }
            >
              {generalDataGlobal[0].menu_items["events"]}
            </Link>
          </div>
          <div className="text-center">
            <Link
              to="/contact-us"
              className={
                "menu-link" +
                (props.activePage === "contact-us" ? " active" : " ")
              }
            >
              {generalDataGlobal[0].menu_items["contact-us"]}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
