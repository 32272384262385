export default function Committees(props) {
    return (
        <div className="text-center">
            <div className="committees-icon">
                <img className="icon-committees mb-4" src={props.image} alt="committees" />
                <h3 >{props.title} </h3>
            </div>
            <div>
                <p className="mb-0">{props.subtitle}</p>
            </div>
        </div>
    )
}