export default function PastWm(props) {
    return (
        <div className="swiper-past-wm position-relative h-100 w-100">
            <div className="past-wm-ratio ratio pastwm-ratio">
                <img className="w-100 h-100" src={props.image} alt="wm" />
            </div>
            <div className="border-on-image-bottom-left-wm"></div>
            <div className="overlay-past-wm"></div>
        </div>
    )
}