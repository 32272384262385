import Banner from "../components/Banner";
import Layout from "../components/Layout";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import PastWm from "../components/PastWm";
import Committees from "../components/Committees";

import Animate from "../components/Animate";
import { useContext, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";

export default function OurLodge(props) {
  const { generalDataGlobal } = useContext(GlobalState);
  const [currentWM, setCurrentWM] = useState(generalDataGlobal[0].lodge_wm_list[0]);

  const sendId = (index) => {
    setCurrentWM(generalDataGlobal[0].lodge_wm_list[index]);
  };



  return (
    <Layout activePage="our-lodge" blackNav={true}>
      <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages['our-lodge'].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages['our-lodge'].image}
            />
          </Helmet>
      <Banner
        blackNav={true}
        title={generalDataGlobal[0].lodge_settings.title}
        banner={generalDataGlobal[0].lodge_settings.banner_image}
      />

      <div className="d-lg-flex d-md-flex d-block lodge-image">
        <div className="custom-lodge-container our-lodge-width d-lg-flex align-items-center d-flex position-relative pt-lg-0 pt-5">
          <div className="position-relative w-100">
            <Animate animate="left">
              <div className="ratio master-ratio">
                {generalDataGlobal[0].lodge_settings.image ? (
                  <img src={generalDataGlobal[0].lodge_settings.image} alt="master" />
                ) : (
                  <img
                    src="/assets/images/temp-images/business-man.jpeg"
                    alt="master"
                  />
                )}

                <div className="border-master"></div>
              </div>
            </Animate>
          </div>
        </div>

        {/* <div className="our-lodge d-lg-flex align-items-center d-flex position-relative pb-lg-0 pt-lg-0 pt-md-5 ps-md-5 pt-5">
                    <div className="row w-100 #38383847">
                        <div className="col-lg col-12">
                            <Animate animate="right">
                                <h2 className="general-title mb-4">The message of the Worshipful Master of Respectable Lodge <b> Acacia 1 </b></h2>
                                <p className="mb-4">This message addresses our <b> Acacia 1 </b> Brothers, our brothers from other crafts, and Friends with
                                    curiosity to know what a Mason does at the lodge and what our secret formula is.</p>
                                <p className="mb-0"><b> Acacia 1 </b> Brothers my promise to you: I am privileged and honored to serve with an exceptionally
                                    fine group of  Officers and members. My success as a Worshipful Master will only come about
                                    through the success of a team. I believe, when everyone grows in knowledge followed by
                                    benevolent actions, we all become stronger and more beneficial to our brothers and community.
                                    I would hope to strengthen the <b> Acacia 1 </b> role in this mission. We’ve made <b> Acacia 1 </b> our home, built on
                                    a solid and trustful base. Our main aim is to have a positive impact on the community and to
                                    continue to attract ambitious members.
                                </p>
                                <p>I plan to move the lodge forward into international and local community service through
                                    charitable involvement. I look forward to working with the entire Craft with well-informed
                                    brethren who will always be available to offer wise advice. </p>
                            </Animate>
                        </div>
                    </div>
                </div> */}
      </div>

      {generalDataGlobal[0].lodge_sections.map((section, index) => (
        <div
          key={index}
          className="our-lodge d-lg-flex align-items-center d-flex position-relative "
        >
          {
            (index === 0 || (index === generalDataGlobal[0].lodge_sections.length -1)) ? 
            <img
              className="lodge-bg"
              src="/assets/images/fixed-images/lodge-bg.svg"
              alt="background"
            /> : null

          }
          <div className="row w-100 pt-4 pt-lg-0 add-scrollbar" >
            <div className={"col d-flex align-items-center" + (index === 0 ? " give-pt-10" : "")}>
              <Animate animate="right">
                <div
                  className="our-loge-info"
                  dangerouslySetInnerHTML={{
                    __html: section.text,
                  }}
                ></div>
              </Animate>
            </div>
          </div>
        </div>
      ))}

      {/* <div className="our-lodge d-lg-flex align-items-center d-flex position-relative">
                <div className="row w-100 pb-lg-5 add-scrollbar">
                    <div className="col-12">
                        <Animate animate="right">
                            <p className="mb-0"><b> Acacia 1 </b> Brothers did a tremendous effort to achieve the yearly objectives by having active
                                committees: including social media activities, charitable work, education, and the introduction of
                                a new website, new logo, and new lodge.

                            </p>
                            <p className="mb-0"><b> Acacia 1 </b> adopted an open-minded approach to collaborate with lodges worldwide, exchange
                                knowledge and experience to enrich our way of thinking and align ourselves with Masonic
                                philosophy.


                            </p>
                            <p>Now more than ever, the world needs a person of honor, love, respect, and integrity. A person who
                                seeks truth and shares Brotherly love with everyone around the world, no matter their race,
                                religion, social status, or origin.
                            </p>
                            <p className="mb-4">
                                Masons live by virtues that help build character and foster manners that represent a universally
                                best model of a human being.
                            </p>
                            <p className="mb-0"><b> Acacia 1 </b> is looking forward to welcoming you into its lodge to join its mission to build a better future
                                for the world.</p>
                        </Animate>
                    </div>
                    <div className="col-12 pt-5">
                        <Animate animate="right">
                            <div className="our-loge-info">
                                <h4 className="mb-0">WM</h4>
                                <h4 className="mb-0">M. J. Haddad</h4>
                                <h4 className="mb-0">Beirut May 1, 2022</h4>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div> */}

      <div className=" custom-container wms lodge swiper-width-responsive d-lg-flex align-items-center d-flex position-relative pt-5">
        <div className="align-items-center swiper-width">
          <div className="pe-lg-5 me-lg-5 add-padding-lodge pb-md-4 give-width-60">
            <Animate animate="left">
              {currentWM ? (
                <>
                  <h2 className="general-title mb-4">
                    {" "}
                    {generalDataGlobal[0].lodge_settings.wm_title}{" "}
                  </h2>
                  <h3 className="past-wm">{currentWM.title}</h3>
                  <p className="">{currentWM.subtitle}</p>
                </>
              ) : null}
            </Animate>
          </div>
          <div className="position-relative  give-width-40 ">
            <Swiper
              // navigation={true}
              onSlideChange={(e) => sendId(e.realIndex)}
              className="mySwiper py-1"
              slidesPerView={1.4}
              centeredSlides={true}
              spaceBetween={100}
              // direction="horizontal"
              loop={true}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                991.98: {
                  slidesPerView: 3,
                  spaceBetween: 80,
                  centeredSlides: true,
                },
                // when window width is >= 767.98px
                767.98: {
                  slidesPerView: 2,
                  spaceBetween: 80,
                  centeredSlides: true,
                  // direction: "horizontal",
                },

                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 40,
                },
                // when window width is >= 480px
                575.98: {
                  slidesPerView: 2.5,
                  spaceBetween: 50,
                  centeredSlides: true,

                },
              }}
            >
              {generalDataGlobal[0].lodge_wm_list.map((wm, index) => (
                <SwiperSlide key={index}>
                  <PastWm image={wm.image !== null ? wm.image : "/assets/images/temp-images/business-man.jpeg"} />
                </SwiperSlide>
              ))}

              <div className="swiper-button-prev lodge-arrows">
                <svg
                  id="Component_19_4"
                  data-name="Component 19 – 4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="30"
                  viewBox="0 0 50 30"
                >
                  <g
                    className="fill-stroke"
                    id="Polygon_3"
                    data-name="Polygon 3"
                    fill="none"
                  >
                    <path d="M25,0,50,30H0Z" stroke="none" />
                    <path
                      d="M 25 1.562044143676758 L 2.135036468505859 29 L 47.86496353149414 29 L 25 1.562044143676758 M 25 0 L 50 30 L 0 30 L 25 0 Z"
                      stroke="none"
                      fill="#f6c12c"
                    />
                  </g>
                </svg>
              </div>
              <div className="swiper-button-next lodge-arrows">
                <svg
                  id="Component_19_5"
                  data-name="Component 19 – 5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="30"
                  viewBox="0 0 50 30"
                >
                  <g
                    className="fill-stroke"
                    id="Polygon_3"
                    data-name="Polygon 3"
                    transform="translate(50 30) rotate(180)"
                    fill="none"
                  >
                    <path d="M25,0,50,30H0Z" stroke="none" />
                    <path
                      d="M 25 1.562044143676758 L 2.135036468505859 29 L 47.86496353149414 29 L 25 1.562044143676758 M 25 0 L 50 30 L 0 30 L 25 0 Z"
                      stroke="none"
                      fill="#f6c12c"
                    />
                  </g>
                </svg>
              </div>
              {}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="last-section-lodge-container d-lg-flex align-items-center d-flex position-relative justify-content-center">
        <img
          className="committees-bg-left"
          src="/assets/images/temp-images/committees-bg-left.svg"
          alt="background"
        />
        <img
          className="committees-bg-right"
          src="/assets/images/temp-images/committees-bg-right.svg"
          alt="background"
        />
        <div className="row w-100 committees">
          <div className="col-12">
            <Animate animate="left">
              <h2 className="general-title mb-lg-5 mb-4 text-center">
                {generalDataGlobal[0].lodge_settings.committees_title}
              </h2>
            </Animate>
          </div>

          {generalDataGlobal[0].lodge_committee.map((committee, index) => (
            <div key={index} className="col-lg-4 col-md-6 mb-4">
              <Animate animate="left">
                <Committees
                  image={committee.icon}
                  title={committee.title}
                  subtitle={committee.description}
                />
              </Animate>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
