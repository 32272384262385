import Stars from "../components/Stars";
import { Link } from "react-router-dom";
import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";

export default function NotFound() {
    const { generalDataGlobal } = useContext(GlobalState);
    return (
        <div className="scroll-container not-found justify-content-center">
            <Stars />
            <div className="full-width not-found-content align-items-center d-flex overflow-hidden justify-content-center">
                <div className="row ">
                    <div className="col text-center">
                        <img className="not-found-img mb-5" src={generalDataGlobal[0]?.not_found_page.image} alt="404" />
                        <h2 className="mb-4">{generalDataGlobal[0]?.not_found_page.title}</h2>
                        <p className="mb-4">{generalDataGlobal[0]?.not_found_page.subtitle}</p>

                        <Link to="/">
                            <button className="button black-button">{generalDataGlobal[0]?.not_found_page.button}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}