import { useContext, useState, useEffect } from "react";
import Banner from "../components/Banner";
import EventsComponent from "../components/EventsComponent";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Animate from "../components/Animate";
import Popup from "../components/Popup";
import Api from "../components/api";
import { useNavigate } from "react-router-dom";

import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";

export default function PreviousEvents() {
  const navigate = useNavigate();

  let { slug } = useParams();
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [singlePageData, setSinglePageData] = useState();
  const { generalDataGlobal } = useContext(GlobalState);

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "events/" + slug,
      callback: (res) => {
        setSinglePageData(res.data);
      },catch404: () =>{
        navigate("/404");
      }
    });
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />

      <Layout activePage="events">
      <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages.events.title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages.events.image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages.events.title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages.events.description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages.events.image}
            />
          </Helmet>
        <Banner
          blackNav={false}
          title="PREVIOUS EVENT"
          banner={singlePageData?.full_path_image}
        />

        <EventsComponent data={singlePageData} />


      {
        singlePageData?.full_path_gallery_images.length > 0 ? 
        
        <div className="  justify-content-center d-lg-flex align-items-center d-flex position-relative pb-5 overflow-hidden ">
          <div className="custom-container events-gallery-width">
            <Animate animate="down">
              <h2 className="general-title mb-4">
                {generalDataGlobal[0].events_settings.gallery_title}
              </h2>
            </Animate>
            <div className="d-lg-flex d-md-block d-md-flex d-block w-100">
            {
              singlePageData?.full_path_gallery_images.map((image, index) => (
                
                    index%2 === 0 ?
                
                <div key={index} className="d-lg-block d-md-block d-sm-flex d-block w-100">
                  <div className="me-lg-5 me-md-4 me-sm-4 me-0 image-gallery">
                    <Animate animate="down">
                      <div
                        className="gallery-hover position-relative pb-5"
                        onClick={() => setGalleryOpen(true)}
                      >
                        <div className="ratio gallery-ratio">
                          <img
                            src={singlePageData?.full_path_gallery_images[index]}
                            alt="single-article"
                          />
                          <div className="border-on-image-gallery"></div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </Animate>
                  </div>
                  {
                      singlePageData?.full_path_gallery_images[index+1] ?
                  <div className="me-lg-5 image-gallery">
                    <Animate animate="down">
                      <div
                        className="gallery-hover position-relative pb-sm-0 pb-5"
                        onClick={() => setGalleryOpen(true)}
                      >
                        <div className="ratio gallery-ratio">
                          <img
                            src={singlePageData?.full_path_gallery_images[index+1]}
                            alt="single-article"
                          />
                          <div className="border-on-image-gallery"></div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </Animate>
                  </div> : null

                  }
                </div> : null
              ))}

              {/* <div className="d-lg-block d-md-block d-sm-flex d-block w-100">
              <div className="me-lg-5 me-md-4 me-sm-4 me-0 image-gallery">
                <Animate animate="down">
                  <div
                    className="gallery-hover position-relative pb-5"
                    onClick={() => setGalleryOpen(true)}
                  >
                    <div className="ratio gallery-ratio">
                      <img
                        src="/assets/images/temp-images/events3.jpeg"
                        alt="single-article"
                      />
                      <div className="border-on-image-gallery"></div>
                      <div className="overlay"></div>
                    </div>
                  </div>
                </Animate>
              </div>
              <div className="me-lg-5 image-gallery">
                <Animate animate="down">
                  <div
                    className="gallery-hover position-relative pb-sm-0 pb-5"
                    onClick={() => setGalleryOpen(true)}
                  >
                    <div className="ratio gallery-ratio">
                      <img
                        src="/assets/images/temp-images/events3.jpeg"
                        alt="single-article"
                      />
                      <div className="border-on-image-gallery"></div>
                      <div className="overlay"></div>
                    </div>
                  </div>
                </Animate>
              </div>
            </div>

            <div className="d-lg-block d-md-block d-sm-flex d-block w-100">
              <div className="me-lg-5 me-md-4 me-sm-4 me-0 image-gallery">
                <Animate animate="down">
                  <div
                    className="gallery-hover position-relative pb-5"
                    onClick={() => setGalleryOpen(true)}
                  >
                    <div className="ratio gallery-ratio">
                      <img
                        src="/assets/images/temp-images/events3.jpeg"
                        alt="single-article"
                      />
                      <div className="border-on-image-gallery"></div>
                      <div className="overlay"></div>
                    </div>
                  </div>
                </Animate>
              </div>
              <div className="me-lg-5  image-gallery">
                <Animate animate="down">
                  <div
                    className="gallery-hover position-relative pb-sm-0 pb-5"
                    onClick={() => setGalleryOpen(true)}
                  >
                    <div className="ratio gallery-ratio">
                      <img
                        src="/assets/images/temp-images/events3.jpeg"
                        alt="single-article"
                      />
                      <div className="border-on-image-gallery"></div>
                      <div className="overlay"></div>
                    </div>
                  </div>
                </Animate>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        : null
      }

        <Popup
          isOpen={galleryOpen}
          close={() => setGalleryOpen(false)}
          modalWindowClass="col-lg-4 col-md-7 col-sm-10 col-9"
        >
          <div className="position-relative">
            <Swiper
              modules={[Navigation]}
              className="mySwiper"
              spaceBetween={20}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
                {
                    singlePageData?.full_path_gallery_images.map((image, index) => (

              <SwiperSlide key={index} className="">
                <div className="ratio gallery-ratio-popup">
                  <img
                    src={image}
                    alt="single-article"
                  />
                </div>
              </SwiperSlide>
                    ))

                }
              {/* <SwiperSlide>
                <div className="ratio gallery-ratio-popup">
                  <img
                    src="/assets/images/temp-images/events3.jpeg"
                    alt="single-article"
                  />
                </div>
              </SwiperSlide> */}
            </Swiper>
            <div>
              <div className="swiper-button-prev gallery-swiper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="50"
                  viewBox="0 0 30 50"
                >
                  <g
                    id="Component_19_8"
                    data-name="Component 19 – 8"
                    transform="translate(30) rotate(90)"
                  >
                    <g
                      className="fill-arrow"
                      id="Polygon_3"
                      data-name="Polygon 3"
                      transform="translate(50 30) rotate(180)"
                      fill="none"
                    >
                      <path d="M25,0,50,30H0Z" stroke="none" />
                      <path
                        d="M 25 1.562044143676758 L 2.135036468505859 29 L 47.86496353149414 29 L 25 1.562044143676758 M 25 0 L 50 30 L 0 30 L 25 0 Z"
                        stroke="none"
                        fill="#f6c12c"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="swiper-button-next gallery-swiper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="50"
                  viewBox="0 0 30 50"
                >
                  <g
                    id="Component_19_7"
                    data-name="Component 19 – 7"
                    transform="translate(0 50) rotate(-90)"
                  >
                    <g
                      className="fill-arrow"
                      id="Polygon_3"
                      data-name="Polygon 3"
                      transform="translate(50 30) rotate(180)"
                      fill="none"
                    >
                      <path d="M25,0,50,30H0Z" stroke="none" />
                      <path
                        d="M 25 1.562044143676758 L 2.135036468505859 29 L 47.86496353149414 29 L 25 1.562044143676758 M 25 0 L 50 30 L 0 30 L 25 0 Z"
                        stroke="none"
                        fill="#f6c12c"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </Popup>
      </Layout>
    </>
  );
}
