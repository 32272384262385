import Animate from "./Animate";

export default function EventsComponent(props) {
    return (
        <div className="e-library  custom-about-container d-lg-flex align-items-center d-flex position-relative py-5 ">
            <div className="w-100 ">
                <div className="row add-scrollbar">
                    <Animate animate="down" >
                        <div className="d-flex flex-column align-items-center">

                            <h2 className="general-title mb-4">{props.data?.title}</h2>
                            <div dangerouslySetInnerHTML={{
                                __html: props.data?.full_text
                            }}></div>
                        </div>
                    </Animate>
                </div>
                <div className=" pt-5">
                    <div className="row justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-center d-flex">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 justify-content-center d-flex pb-sm-0 pb-3">
                            <Animate animate="up">
                                <div className="events-timing">
                                    <div className="text-center">
                                        <img className="pb-3" src="/assets/images/fixed-images/icon.svg" alt="icon" />
                                    </div>
                                    <p>{props.data?.date_formatted}</p>
                                </div>
                            </Animate>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 justify-content-center d-flex pb-sm-0 pb-3">
                            <Animate animate="up">
                                <div className="events-timing">
                                    <div className="text-center">
                                        <img className="pb-3" src="/assets/images/fixed-images/icon.svg" alt="icon" />
                                    </div>
                                    <p>{props.data?.time_from_formatted + "-" + props.data?.time_to_formatted}</p>
                                </div>
                            </Animate>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 justify-content-center d-flex pb-sm-0 pb-3">
                            <Animate animate="up">
                                <div className="events-timing">
                                    <div className="text-center">
                                        <img className="pb-3" src="/assets/images/fixed-images/icon.svg" alt="icon" />
                                    </div>
                                    <a className="remove-a" href={props.data?.location_url} target="_blank" rel="noreferrer">

                                        <p>{props.data?.location}</p>
                                    </a>
                                </div>
                            </Animate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}