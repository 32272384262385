import { Link } from "react-router-dom";
import Animate from "./Animate";

export default function Event(props) {
  return (
    <div
      className={
        "events libraries d-lg-flex align-items-end col-lg-4 col-md-6 d-flex position-relative px-3 py-5" +
        (props.index % 2 ? " " : " black ")
      }
    >
      {props.index === 2 && (
        <img className={props.classBg} src={props.bg} alt="background" />
      )}

     
      <Link to={props.link} className="w-100">
        <Animate animate="left">
          <div className="row fix-padding-mobile">
            <div className="col">
              <div className="article event position-relative">
                <div className="image-article events">
                  <h2 className="flex-grow-1 h-100">{props.title}</h2>
                  <div className="ratio library-ratio shadow">
                    <img src={props.image} alt="article" />
                  </div>
                </div>
                <div>
                  <p className="event-date pt-3 mb-0">{props.date}</p>
                </div>
                <div className="show-on-hover">
                  <p className="mb-0 mt-3">{props.paragraph}</p>
                  <div className="pt-3 pb-lg-0 pb-1">
                    <Link to={props.link}>
                      <button className={"button yellow-button" + (props.page === "events" ? " events-btn" : "")}>
                        {props.button}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Animate>
      </Link>
    </div>
  );
}
