import Animate from "../components/Animate";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";
import { useContext } from "react";
import { Helmet } from "react-helmet";



export default function AboutUs(props) {
  const { generalDataGlobal } = useContext(GlobalState);
  const lodges = generalDataGlobal[0]?.our_lodges;

  return (
    <Layout activePage="about-us" blackNav={true}>
      <Helmet>
        <title>{generalDataGlobal[0]?.seo_pages['about-us'].title}</title>
        <meta
          name="title"
          content={generalDataGlobal[0]?.seo_pages['about-us'].title}
        />
        <meta
          name="description"
          content={generalDataGlobal[0]?.seo_pages['about-us'].description}
        />
        <meta
          property="og:title"
          content={generalDataGlobal[0]?.seo_pages['about-us'].title}
        />
        <meta
          property="og:description"
          content={generalDataGlobal[0]?.seo_pages['about-us'].description}
        />
        <meta
          property="og:image"
          content={generalDataGlobal[0]?.seo_pages['about-us'].image}
        />
        <meta
          property="twitter:title"
          content={generalDataGlobal[0]?.seo_pages['about-us'].title}
        />
        <meta
          property="twitter:description"
          content={generalDataGlobal[0]?.seo_pages['about-us'].description}
        />
        <meta
          property="twitter:image"
          content={generalDataGlobal[0]?.seo_pages['about-us'].image}
        />
      </Helmet>
      <Banner
        blackNav={true}
        title={generalDataGlobal[0].about_us_settings.title}
        banner={generalDataGlobal[0].about_us_settings.banner_image}
      />

      <div className="about-overview custom-about-container d-lg-flex align-items-center d-flex position-relative py-5">
        <img
          className="overview-bg"
          src="/assets/images/fixed-images/overview.svg"
          alt="background"
        />
        <img
          className="overview-bg-mobile d-lg-none"
          src="/assets/images/fixed-images/overview.svg"
          alt="background"
        />
        <div className="row">
          <div className="col">
            <Animate animate="left">
              <div className="general-title" dangerouslySetInnerHTML={{
                __html: generalDataGlobal[0].about_us_settings.first_section
              }}>

              </div>
            </Animate>
          </div>
        </div>
      </div>

      <div className="history-bg-color full-width d-lg-flex align-items-center d-flex position-relative pb-lg-0 pb-5">
        <img
          className="overview-bg d-none d-lg-block"
          src="/assets/images/fixed-images/overview.svg"
          alt="background"
        />
        <img
          className="history-bg"
          src="/assets/images/fixed-images/history.png"
          alt="background"
        />
        <div className="custom-about-container ">
          <div className="row gx-lg-5 add-scrollbar" >
            <div className="col-12">
              <Animate animate="down">
                <h2 className="general-title mb-4">{generalDataGlobal[0].about_us_settings.section2_title}</h2>
                <div dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].about_us_settings.section2_text1
                }}>

                </div>
              </Animate>
            </div>
            <div className="col-lg-5 col-md-5 pb-md-0 pb-4">
              <Animate animate="left">
                <div className="position-relative">
                  <div className="ratio history-image">
                    <img
                      src={generalDataGlobal[0].about_us_settings.section2_image}
                      alt="history"
                    />
                    <div className="border-on-image-bottom-right"></div>
                  </div>
                </div>
              </Animate>
            </div>
            <div className="col-lg-7 col-md-7">
              <Animate animate="right">
                <div dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].about_us_settings.section2_text2
                }}>
                </div>
              </Animate>
            </div>
          </div>
        </div>
      </div>


      <div className={"about-overview custom-about-container d-lg-flex align-items-center d-flex position-relative"}>
        <img
          className="mission-bg"
          src="/assets/images/fixed-images/mission.svg"
          alt="background"
        />
        <div className="row w-100 justify-content-center contents">
          <div className="col-lg-12 add-scrollbar d-lg-flex align-items-center mission-col">
            <div className="row w-100">
              {generalDataGlobal[0].about_us_list.map((item, index) =>

                <div key={index} className="col-lg-4 order-lg-2 order-3 mb-lg-1 mb-2">
                  <Animate animate="down">
                    <img src={item.image} className="about-icons" alt="about-pics" />
                  </Animate>
                  <Animate animate="down">
                    <h2 className="general-title">{item.title}</h2>
                  </Animate>
                  <Animate animate="up">
                    <div className="mb-lg-5 mb-0" dangerouslySetInnerHTML={{
                      __html: item.description
                    }}>

                    </div>
                  </Animate>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>


      <div className="our_lodge custom-about-container mb-5">
        <div className="row">
          <div className="col-12">
            <h2 className="general-title mb-4">
              {generalDataGlobal[0].about_us_settings.our_lodge_section_title}
            </h2>
          </div>
          {
            lodges?.length > 0 ?
              lodges.map((lodge, index) => (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4" key={index}>
                  <div className="ratio ratio-1x1">
                    <img className="position-absolute h-100 w-100 cover" src={lodge?.logo} alt={lodge.title}></img>
                  </div>
                  <div className="text-center mt-4 mb-3">
                    <h2>{lodge.title}</h2>
                  </div>
                </div>

              ))
              :

              null
          }
        </div>

      </div>
    </Layout >
  );
}
