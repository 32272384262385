import { Link } from "react-router-dom";
import EventsHome from "../components/EventsHome";
import IconsHomepage from "../components/IconsHomepage";
import Layout from "../components/Layout";
import Animate from "../components/Animate";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { useContext, useEffect, useState } from "react";
import Popup from "../components/Popup";
import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";


export default function Home(props) {
  const [articlePopup, setArticlePopup] = useState(false);
  const { generalDataGlobal } = useContext(GlobalState);
  // const [fbFeed, setFbFeed] = useState();
  const [instaFeed, setInstaFeed] = useState([]);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});


  // const navigate = useNavigate();


  useEffect(() => {
    setGeneralDataApiOptions({
      url: "instagram",
      callback: (res) => {
        setInstaFeed(res.data);;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   var url =
  //     "https://graph.facebook.com/v14.0/me?fields=id%2Cname%2Cposts%7Bfull_picture%2Ccaption%2Ccreated_time%2Cmessage%2Cpermalink_url%7D&access_token=EAAKD5xIQStEBAEGwyIUSWWa4XrJJ9UZBxQqSMytd14aUbgH9Rs106NSvoZCpqymdiSO3Mb2P3cRDWQUCBN1MXgGlZBTkavo9J5g8sNZA3QC9cXZCDvOG1kmKc3f80bwAI57PPDreJXGYO8ZB7UpIAtlUaQ6cExmmbr6RbZAT28AtQZDZD";
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", url);

  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState === 4) {
  //       setFbFeed(JSON.parse(xhr.responseText).posts.data);
  //     }
  //   };

  //   xhr.send();
  //   window.triggerScroll();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function scrollSection() {
    const element = document.getElementById("about-section");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  useEffect(() => {
    // let articlePopup = localStorage.getItem("acacia1ArticlePopup");
    // if (articlePopup !== generalDataGlobal[0].home_settings.popup_article.slug) {
    setArticlePopup(true);
    // localStorage.setItem("acacia1ArticlePopup", generalDataGlobal[0].home_settings.popup_article.slug);
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function articleRedirection() {
    setArticlePopup(false);
    // navigate("/e-library/" + generalDataGlobal[0].home_settings.popup_article.slug);
  }



  return (
    <>
      <Api options={generalDataApiOptions} />

      <Layout activePage="home">
        <Helmet>
          <title>{generalDataGlobal[0]?.seo_pages.home.title}</title>
          <meta
            name="title"
            content={generalDataGlobal[0]?.seo_pages.home.title}
          />
          <meta
            name="description"
            content={generalDataGlobal[0]?.seo_pages.home.description}
          />
          <meta
            property="og:title"
            content={generalDataGlobal[0]?.seo_pages.home.title}
          />
          <meta
            property="og:description"
            content={generalDataGlobal[0]?.seo_pages.home.description}
          />
          <meta
            property="og:image"
            content={generalDataGlobal[0]?.seo_pages.home.image}
          />
          <meta
            property="twitter:title"
            content={generalDataGlobal[0]?.seo_pages.home.title}
          />
          <meta
            property="twitter:description"
            content={generalDataGlobal[0]?.seo_pages.home.description}
          />
          <meta
            property="twitter:image"
            content={generalDataGlobal[0]?.seo_pages.home.image}
          />
        </Helmet>
        <div className="stars-background full-width align-items-center d-flex overflow-hidden">
          <div className="container-fluid">
            <div className="row justify-content-center py-md-0 py-5">
              <Animate animate="left">
                <div className="light">
                  <h1 className="text-center mb-lg-5 pb-lg-4 ">
                    {generalDataGlobal[0].home_settings.first_section_title}
                  </h1>
                </div>
              </Animate>

              {generalDataGlobal[0].home_list.map((list, index) => (
                <div className="col-lg-3 col-md-4 " key={index}>
                  <IconsHomepage
                    title={list.title}
                    subtitle={list.subtitle}
                    firstIcon={list.icon}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="scroll-button align-self-end me-5 pe-3 position-absolute end-0"
            onClick={scrollSection}
          >
            <div className="position-relative pb-5">
              <img src="/assets/images/fixed-images/scroll.svg" alt="scroll" />
              <p className="mb-0">{generalDataGlobal[0].home_settings.scroll_title}</p>
            </div>
          </div>
        </div>

        <div
          className="home-section full-width d-lg-flex align-items-center d-flex position-relative js-white-section give-pb"
          id="about-section"
        >
          <img
            className="about-bg-section"
            src="/assets/images/fixed-images/about-bg.svg"
            alt="about-bg-section"
          />
          <div className=" py-lg-0 pt-5 mt-lg-0 mt-3">
            <div className="row gx-5  ">
              <h1 className="mb-lg-0">
                {generalDataGlobal[0].home_settings.about_title}
              </h1>

              <div
                className="col-12 pb-4"
                dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].home_settings.about_first_text,
                }}
              ></div>
              <div className="col-lg-6 col-md-6">
                <Animate animate="left">
                  <div className="position-relative">
                    <div className="ratio about-us-ratio">
                      <img
                        className=" shadow"
                        src={generalDataGlobal[0].home_settings.about_image}
                        alt="about-us"
                      />
                      <div className="border-on-image-left"></div>
                    </div>
                  </div>
                </Animate>
              </div>
              <div className="col-lg-6 col-md-6 pt-4 pt-md-0">
                <div
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].home_settings.about_second_text,
                  }}
                ></div>
                <div className="pt-5 text-md-start text-center">
                  <Link to="/about-us">
                    <button className="button yellow-button">
                      {generalDataGlobal[0].home_settings.about_button}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-section full-width d-lg-flex align-items-center d-flex position-relative overflow-hidden give-pb">
          <div className=" mt-lg-0 mt-3 ">
            <div className="row gx-5  ">
              <h1 className="mb-lg-0">
                {generalDataGlobal[0].home_settings.lodge_title}
              </h1>
              <div className="col-12 mb-4">
                <div
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].home_settings.lodge_first_text,
                  }}
                ></div>
              </div>

              <div className="col-lg-8 col-md-6 order-lg-2 order-3 pt-4 pt-md-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].home_settings.lodge_second_text,
                  }}
                ></div>

                <div className="text-md-start text-center pt-5">
                  <Link to="/our-lodge">
                    <button className="button black-button">
                      {generalDataGlobal[0].home_settings.lodge_button}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 pt-lg-0 order-lg-3 order-2">
                <Animate animate="left">
                  <div className="position-relative">
                    <div className="ratio our-lodge-ratio">
                      <img
                        className=" shadow"
                        src={generalDataGlobal[0].home_settings.lodge_image}
                        alt="about-us-section"
                      />
                      <div className="border-on-image-down-left"></div>
                    </div>
                  </div>
                </Animate>
              </div>
            </div>
          </div>
        </div>

        <div className="stars-background-home full-width align-items-center align-self-center position-relative js-white-section remove-d-flex give-pb">
          <img
            className="elibrary-bg-section"
            src="/assets/images/fixed-images/lodge.png"
            alt="background"
          />
          <img
            className="lodge-bg-section"
            src="/assets/images/fixed-images/eye.svg"
            alt="background"
          />
          <div className="  align-self-center  e-library-home ">
            <div className="row ">
              <h1 className="mb-lg-0">
                {generalDataGlobal[0].home_settings.e_library_title}
              </h1>
              <div className="col-lg-6 col-md-7 col-md-7">
                <div
                  className="mb-lg-0 mb-4 me-lg-5"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].home_settings.e_library_text,
                  }}
                ></div>
                <div className="pt-5 d-none d-md-block">
                  <Link to="/e-library">
                    <button className="button yellow-button">
                      {generalDataGlobal[0].home_settings.e_library_button}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-md-5">
                <div className="position-relative">
                  <Animate animate="left">
                    <div className="ratio e-library-ratio ">
                      <img
                        className="shadow"
                        src={generalDataGlobal[0].home_settings.e_library_image}
                        alt="e-library"
                      />
                      <div className="border-on-image-right"></div>
                    </div>
                  </Animate>
                </div>
                <div className="d-md-none d-block text-center pt-5">
                  <Link to="/e-library">
                    <button className="button yellow-button">
                      {generalDataGlobal[0].home_settings.e_library_button}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {generalDataGlobal[0].home_settings.featured_events.length > 0 ? (
          <div className="events-home align-self-center stars-background-home align-items-center justify-content-start  d-lg-flex position-relative give-pb">
            <img
              className="events-bg-section"
              src="assets/images/fixed-images/events-bg.svg"
              alt="background"
            />
            <div className="  adjust-events align-self-center w-100">
              <div className="events-container-left">
                <h1 className="mb-lg-0 ">
                  {generalDataGlobal[0].home_settings.events_title}
                </h1>
                <p className="mb-5 ">
                  {generalDataGlobal[0].home_settings.events_subtitle}
                </p>
              </div>
              <div className="swiper-container position-relative">
                <Swiper
                  className="events-swiper px-lg-3"
                  slidesPerView={1.2}
                  // centeredSlides={true}
                  spaceBetween={100}
                  loop={false}
                  modules={[Navigation]}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    991.98: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                      allowTouchMove: false,
                    },
                    // when window width is >= 767.98px
                    767.98: {
                      slidesPerView: 2.3,
                      spaceBetween: 30,
                      allowTouchMove: true,
                      centeredSlides: true,
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      },
                    },

                    320: {
                      slidesPerView: 1.5,
                      spaceBetween: 50,
                      centeredSlides: true

                    },
                    // when window width is >= 480px
                    575.98: {
                      slidesPerView: 2.5,
                      spaceBetween: 50,
                      centeredSlides: true

                    },
                  }}
                >
                  {generalDataGlobal[0].home_settings.featured_events.map(
                    (event, index) => (
                      <SwiperSlide key={index} className="center-slide">
                        <Link to={event.past_event === 0 ? ("upcoming-events/" + event.slug) : ("previous-events/" + event.slug)}>
                          <EventsHome
                            title={event.title}
                            image={event.full_path_image}
                            date={event.date}
                          />
                        </Link>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
                <div>
                  <div className="swiper-button-prev swiper-events d-lg-none">
                    <img
                      src="/assets/images/fixed-images/prev.svg"
                      alt="prev"
                    />
                  </div>
                  <div className="swiper-button-next swiper-events d-lg-none">
                    <img
                      src="/assets/images/fixed-images/next.svg"
                      alt="next"
                    />
                  </div>
                </div>
                <div className="text-center pt-5">
                  <Link to="/events">
                    <button className="button black-button">
                      {generalDataGlobal[0].home_settings.events_button}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="first-step stars-background-home w-100 align-items-center d-lg-flex position-relative overflow-hidden">
          <div className=" events-container-right w-100 position-relative pb-lg-0 pb-5 mb-lg-0 mb-3">
            <div className="row w-100 last-section-home gx-lg-5 mt-lg-0 mt-3 align-items-center  ">
              <div className="col-lg-6 col-md-6">
                <div className="">
                  <div className="">
                    <h3 className="mb-lg-4">
                      {generalDataGlobal[0].home_settings.contact_titlte}
                    </h3>
                    <div
                      className="mb-lg-0 mb-4"
                      dangerouslySetInnerHTML={{
                        __html: generalDataGlobal[0].home_settings.contact_text,
                      }}
                    ></div>
                  </div>
                  <div className="pt-lg-5 pt-4 text-md-start text-center d-none d-md-block">
                    <Link to="/contact-us">
                      <button className="button black-button">
                        {generalDataGlobal[0].home_settings.contact_button}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="position-relative">
                  <Animate animate="up">
                    <div className="ratio last-home-image">
                      <img
                        src={generalDataGlobal[0].home_settings.contact_image}
                        alt="BE A FREEMASON"
                      />
                      <div className="border-on-image-bottom-left"></div>
                    </div>
                  </Animate>
                </div>
              </div>
              <div className="pt-5 text-center d-md-none d-block">
                <Link to="/contact-us">
                  <button className="button black-button">
                    {generalDataGlobal[0].home_settings.contact_button}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="events-container-right fb-feed-container d-flex flex-column justify-content-center ">
            <div className="  adjust-events align-self-center pb-lg-0 pt-lg-0 pt-5 mt-lg-0 mt-3 pe-lg-5 me-lg-5">

              <h3 className="mb-lg-4  px-lg-3 fb-feed-title">{generalDataGlobal[0].home_settings.facebook_title}</h3>
              <div className="container">
                <div className="row justify-content-center ">
                  {fbFeed?.map((post, index) =>
                    index < 3 ? (
                      <div
                        key={index}
                        className="col-lg-4 col-sm-4 col-12 fb-feed-col"
                      >
                        <div class="position-relative h-100">
                          <div class=" events-home-ratio fb-feed-no-pad shadow h-100">

                            <div className="d-flex flex-column h-100">
                              <img
                                className="fb-feed-img"
                                src={post.full_picture}
                                alt="test event 2"
                              />
                              <div className="d-flex flex-column justify-content-center p-2 flex-grow-1 align-items-center">
                                <div>
                                  <p>
                                    {new Date(post.created_time).toDateString()}
                                  </p>
                                </div>

                                {post.message ? (
                                  <div>
                                    <p>
                                      {post.message}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <a href={post.permalink_url} target="_blank" rel="noreferrer">

                              <div class="overlay"></div>
                            </a>
                          </div>
                        </div>
                      </div>

                    ) : null,


                  )}
                  <div className="pt-lg-5 pt-4 text-md-start text-center d-md-block">
                    <a href={generalDataGlobal[0].home_settings.facebook_link} target="_blank" rel="noreferrer">
                      <button className="button black-button">
                        {generalDataGlobal[0].home_settings.facebook_title}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="home-section">
          {/* <div className="custom-contact-container d-block position-relative pb-lg-0 pb-5"> */}
          <Animate animate="left">
            <div className="d-block ms-lg-4 mb-lg-0 mb-4 me-5 pb-4">
              <h2 className="general-title contact mb-lg-2 mb-3 text-center">
                {generalDataGlobal[0].contact_data.follow_title}
              </h2>
              <div className="text-center text-start pb-lg-0 pb-3">
                <a href={generalDataGlobal[0].contact_data.follow_url} target="_blank" rel="noreferrer">

                  <button className="button black-button">
                    {generalDataGlobal[0].contact_data.follow_button}
                  </button>
                </a>
              </div>
            </div>
          </Animate>
          <Swiper
            className="insta-feed-swiper"
            allowTouchMove={true}
            slidesPerView={3}
            spaceBetween={10}
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
            }}
            breakpoints={{
              767.98: {
                slidesPerView: 3,
                spaceBetween: 40,
                allowTouchMove: true,
              },

              320: {
                slidesPerView: 1.5,
                spaceBetween: 50,
              },
              // when window width is >= 480px
              575.98: {
                slidesPerView: 2.5,
                spaceBetween: 50,
              },
            }}
          >
            {instaFeed?.map((image, index) =>

              <SwiperSlide key={index}>
                <div className="insta-hover position-relative">
                  <a href={image.permalink} target="_blank" rel="noreferrer">
                    <div className="ratio insta-feed">

                      <img
                        // src={image.media_url}
                        src={image?.thumbnail_url ? image?.thumbnail_url : image?.media_url}
                        alt="insta"
                      />
                    </div>
                  </a>
                </div>
              </SwiperSlide>)}


          </Swiper>
        </div>
        <Popup
          isOpen={articlePopup}
          close={() => setArticlePopup(false)}
          modalWindowClass="col-lg-7 col-md-9 col-sm-10 col-11"
        >
          <div className="white-popup p-sm-5 p-4" >
            <div className={"row " + (generalDataGlobal[0].home_settings.popup_article.isArabic === 1 ? "arabic" : "")}>
              <h2 className="text-center mb-4">
                {generalDataGlobal[0]?.home_settings.article_month_title}
              </h2>
              <div className="col-lg-5 col-md-5 ">
                <div className="position-relative">
                  <div className="ratio article-popup-ratio">
                    <img
                      src={
                        generalDataGlobal[0]?.home_settings.popup_article.full_path_image
                      }
                      alt="article-view"
                    />
                    <div className="border-on-image-left"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-7  pt-md-0 pt-4">
                <div className="flex-column d-flex h-100">
                  <h3 className="mb-3">
                    {generalDataGlobal[0]?.home_settings.popup_article.title}
                  </h3>
                  <div className="flex-grow-1 ">
                    <p>
                      {generalDataGlobal[0]?.home_settings.popup_article.small_text}
                    </p>
                  </div>
                  <div
                    className="text-md-start text-center"
                    onClick={articleRedirection}
                  >
                    <a href={"/e-library/" + generalDataGlobal[0].home_settings.popup_article.slug}>
                      <button className="button yellow-button">
                        {generalDataGlobal[0]?.home_settings.e_library_button}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </Layout>
    </>
  );
}
