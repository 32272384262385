import Animate from "../components/Animate";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import { useParams } from "react-router-dom";
import Api from "../components/api";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ELibrarySinglePage() {
  const navigate = useNavigate();
  let { slug } = useParams();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [singlePageData, setSinglePageData] = useState();
  const { generalDataGlobal } = useContext(GlobalState);

  useEffect(() => {
    document.querySelector('body').style.overflow = null;
    document.querySelector('html').style.overflow = null;
  }, []);


  useEffect(() => {
    setGeneralDataApiOptions({
      url: "e-library/" + slug,
      callback: (res) => {
        setSinglePageData(res.data);
        window
          .jQuery("#flipbookContainer")
          .flipBook(
            res.data.full_path_download_file,
            { webgl: true }
          );
      },
      catch404: () => {
        navigate("/404");
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />

      <Layout activePage="e-library" blackNav={true}>
      <Helmet>
        <title>{generalDataGlobal[0]?.seo_pages['e-library'].title}</title>
        <meta
          name="title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          name="description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="og:title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          property="og:description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="og:image"
          content={generalDataGlobal[0]?.seo_pages['e-library'].image}
        />
        <meta
          property="twitter:title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          property="twitter:description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="twitter:image"
          content={generalDataGlobal[0]?.seo_pages['e-library'].image}
        />
      </Helmet>
      {/* <button onClick={() => setChange(!change)}> click </button> */}
      <Banner
        blackNav={true}
        title={generalDataGlobal[0].e_library_settings.title}
        banner={singlePageData?.full_path_image}
      />

      <div className="e-library custom-about-container d-lg-flex align-items-center d-flex position-relative pt-5">
        <img
          className="e-library-single-bg"
          src="/assets/images/fixed-images/library-single.png"
          alt="background"
        />
        <div className={"row add-scrollbar" + (singlePageData?.isArabic === 1 ? "arabic" : "")}>
          <div className="col">
            <Animate animate="up">
              <h2 className={"general-title mb-4" + (singlePageData?.isArabic === 1 ? " text-end arabic" : " ")}>{singlePageData?.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: singlePageData?.first_description,
                }} className={(singlePageData?.isArabic === 1 ? "arabic" : "")}
              ></div>
            </Animate>
          </div>
        </div>
      </div>

      {singlePageData?.download_file !== null ? (

        <div className="canvas-container library-ratio position-relative">
          <div className="_df_book" id="flipbookContainer"></div>
        </div>
      ) : (
        singlePageData?.sections.map((section, index) => (
         
          <div
            key={index}
            className={"add-scrollbar arabic-content align-self-center e-library custom-about-container d-lg-flex align-items-center d-flex position-relative  " + ( section.isArabic === 1 ? " dir-rtl" : " ")}
          >
            <div className="row my-auto">
              <div
                className={
                  "col-12" + (index % 2 === 0 ? " order-last" : " order-first")
                }
              >
                <Animate animate="down">
                  <div
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: section.text,
                    }}
                  ></div>
                </Animate>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 pb-md-0 pb-sm-0 pb-4">
                <Animate animate="down">
                  <div className="position-relative">
                    <div className="ratio ratio-single-page">
                      <img
                        src={section.full_path_first_image}
                        alt="single-article"
                      />
                      <div className="border-on-image"></div>
                    </div>
                  </div>
                </Animate>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 pb-md-0 pb-sm-0 pb-4">
                <Animate animate="down">
                  <div className="position-relative">
                    <div className="ratio ratio-single-page">
                      <img
                        src={section.full_path_second_image}
                        alt="article-single"
                      />
                      <div className="border-on-image"></div>
                    </div>
                  </div>
                </Animate>
              </div>
            </div>
          </div>
        ))
      )}

      {/* <div className="e-library custom-about-container d-lg-flex align-items-center d-flex position-relative pb-5 ">
                <img className="bg-under-text" src="/assets/images/fixed-images/single-article.png" alt="background" />
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 pb-md-0 pb-sm-0 pb-4 order-lg-1  order-2">
                        <Animate animate="up">
                            <div className="position-relative">
                                <div className="ratio ratio-single-page">
                                    <img src="/assets/images/temp-images/contact.jpg" alt="single-article" />
                                    <div className="border-on-image"></div>
                                </div>
                            </div>
                        </Animate>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pb-md-0 pb-sm-0 pb-0 order-lg-2  order-3">
                        <Animate animate="up">
                            <div className="position-relative">
                                <div className="ratio ratio-single-page">
                                    <img src="/assets/images/temp-images/contact.jpg" alt="single-article" />
                                    <div className="border-on-image"></div>
                                </div>
                            </div>
                        </Animate>
                    </div>

                    <div className="col-12 order-lg-3  order-1">
                        <Animate animate="up">
                            <p className="mb-0 mt-lg-4 mt-md-4 mt-sm-4 mb-4">There are three Brothers who are assigned to an Investigation Committee and will attend the interview.
                                Usually one of the Brothers will lead the conversation and ask the main questions. The other two will
                                observe and chime in with items that may have been forgotten. In general, they will rehash the questions
                                on the back of the petition: making sure you believe in “a God,” asking how you found out about
                                Freemasonry and what ultimately drove you to fill out your petition. We will also ask a few personal
                                questions: what you do for work, what are your hobbies outside of work, are you married, or do you have
                                children etc. We suggest you prepare to dedicate at least 20 to 30 minutes for this conversation. With that
                                said, some interviews have been known to last up to an hour depending on the flow of the conversation! </p>
                        </Animate>
                    </div>
                </div>
            </div> */}
    </Layout>
    </>
  );
}
