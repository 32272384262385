import Banner from "../components/Banner";
import Layout from "../components/Layout";

import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/pagination";
import { useContext, useEffect, useState } from "react";
import ContactPopup from "../components/ContactPopup";
import Animate from "../components/Animate";
import Popup from "../components/Popup";
import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";

export default function ContactUs(props) {
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState();
  const { generalDataGlobal } = useContext(GlobalState);
  const [apiOptions, setApiOptions] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState({});
  const [reason, setReason] = useState("");
  const [expectations, setExpectations] = useState("");
  const [fbFeed, setFbFeed] = useState();



  useEffect(() => {
    document.querySelector("body").style.overflowX = successPopup
      ? "hidden"
      : null;
    document.querySelector("html").style.overflowX = successPopup
      ? "hidden"
      : null;
    // document.querySelector('scroll-container').style.overflowX = successPopup ? 'hidden' : null;
  }, [successPopup]);

  // var url =
  //   "https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&access_token=IGQVJVTU1yZAEhCSW5PeTBJRWFuTEhWdEFNal8zMzlhWkhWNlhadDU2a1V1ei1YaWl4OGRUUlF1U202a3E4czg3RnhGaVJvVnRMWGJPdVI4bWE5bk13WjItdnpFYmhZAVzFzMkRGNXBB";

  // var xhr = new XMLHttpRequest();
  // useEffect(() => {
  //   xhr.open("GET", url);
  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState === 4) {
  //       setInstaFeed(JSON.parse(xhr.responseText).data);
  //     }
  //   };

  //   xhr.send();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps


  //if facebook goes off again, check bookmarked to get long lived access token


  //EAAKD5xIQStEBAKkhJRRUYaOBiGCuQ1wH4cJsivx6eYVUog3PAwdyWwBM6df2DbVtpcem9a4mYkRHApZB3kYJywUoIdgdEgnZC7Lgf0yVlpk73TF7ZArg1ywZBgJLSnHTBQO41Ur3AWtZBHqDohDKg786jve1EbsVk1bky7mlnfrsUKn5PQ9Uf

  useEffect(() => {
    var url =
      "https://graph.facebook.com/v14.0/me?fields=id%2Cname%2Cposts%7Bfull_picture%2Cattachments%2Ccreated_time%2Cmessage%2Cpermalink_url%7D&access_token=EAAK2z2e4aXcBAMraVWbcaNoe15nDVDOoaisr1ilp5MnS7s7bcUHFlPemwBmAARhk4rbeKbbVoQyykOZCK6OcICVYX5RTDiK56uQeLYeCEofBEJGuNEqWY2rwbfGoZCVbb87l8SLq9HLe7HmMrNWnPwkESOyG9GEwQ6N4AFI031kY6hrkWv";
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setFbFeed(JSON.parse(xhr.responseText).posts.data);
      }
    };

    xhr.send();
    window.triggerScroll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setGeneralDataApiOptions({
  //     url: "instagram",
  //     callback: (res) => {
  //       setInstaFeed(res.data);;
  //     }
  //   });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contactUs = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("file", file);
    formData.append("reason", reason);
    formData.append("expectations", expectations);

    setApiOptions({
      url: "/contact",
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      callback: (r) => {
        setName("");
        setEmail("");
        setPhone("");
        setFile({});
        setReason("");
        setExpectations("");
        setErrorPopup(false);
        setSuccessPopup(true);
        setTimeout(() => {
          setSuccessPopup(false);
        }, 3000);
      },
      catch422: (errors) => {
        setErrorMessages(errors);
        setSuccessPopup(false);
        setErrorPopup(true);
      },
    });
  };

  return (
    <>

      <Api options={apiOptions} />

      <Layout activePage="contact-us" blackNav={false}>
        <Helmet>
          <title>{generalDataGlobal[0]?.seo_pages['contact-us'].title}</title>
          <meta
            name="title"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].title}
          />
          <meta
            name="description"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].description}
          />
          <meta
            property="og:title"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].title}
          />
          <meta
            property="og:description"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].description}
          />
          <meta
            property="og:image"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].image}
          />
          <meta
            property="twitter:title"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].title}
          />
          <meta
            property="twitter:description"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].description}
          />
          <meta
            property="twitter:image"
            content={generalDataGlobal[0]?.seo_pages['contact-us'].image}
          />
        </Helmet>
        <Banner
          title={generalDataGlobal[0].contact_data.title}
          banner={generalDataGlobal[0].contact_data.image}
        />

        <div className="contact-us d-lg-flex align-items-center d-flex position-relative py-5  ">
          <div className="d-flex flex-column w-100">
            <Animate animate="left">
              <h2 className="general-title mb-4">
                {generalDataGlobal[0].contact_data.details_title}
              </h2>
            </Animate>

            <div className="w-100 d-lg-flex justify-content-between d-none">

              <Animate animate="left">
                <a
                  href={"tel:" + generalDataGlobal[0].contact_data.phone_number}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img src={generalDataGlobal[0].contact_data.phone_icon} alt="icon" />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.phone_number}
                    </p>
                  </div>
                </a>
              </Animate>

              <Animate animate="left">
                <a
                  href={generalDataGlobal[0].contact_data.location_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img
                      src={generalDataGlobal[0].contact_data.location_icon}
                      alt="icon"
                    />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.location_text}
                    </p>
                  </div>
                </a>
              </Animate>

              <Animate animate="left">
                <a
                  href="mailto:acacia1@gmail.Com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img src={generalDataGlobal[0].contact_data.email_icon} alt="icon" />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.email_text}
                    </p>
                  </div>
                </a>
              </Animate>
            </div>

            <div className="d-block d-lg-none">

              <Animate animate="left">
                <a
                  href={"tel:" + generalDataGlobal[0].contact_data.phone_number}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img src={generalDataGlobal[0].contact_data.phone_icon} alt="icon" />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.phone_number}
                    </p>
                  </div>
                </a>
              </Animate>

              <Animate animate="left">
                <a
                  href={generalDataGlobal[0].contact_data.location_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img
                      src={generalDataGlobal[0].contact_data.location_icon}
                      alt="icon"
                    />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.location_text}
                    </p>
                  </div>
                </a>
              </Animate>

              <Animate animate="left">
                <a
                  href="mailto:acacia1@gmail.Com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contact-details align-items-center d-flex mb-4">
                    <img src={generalDataGlobal[0].contact_data.email_icon} alt="icon" />
                    <p className="mb-0 ms-3">
                      {generalDataGlobal[0].contact_data.email_text}
                    </p>
                  </div>
                </a>
              </Animate>
            </div>


            <Animate animate="left">
              <div className="d-flex justify-content-lg-center justify-content-start pt-4">
                {generalDataGlobal[0].social_media.map((item, index) => (
                  <div key={index} className="social-icon me-3">
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img className="" src={item.yellow_icon} alt="social" />
                    </a>
                  </div>
                ))}
              </div>
            </Animate>
          </div>
        </div>

        <div className="custom-contact-container get-touch d-lg-flex align-items-center d-flex position-relative pb-5 ">
          <img
            className="contact-bg"
            src="/assets/images/fixed-images/contact-bg.svg"
            alt="background"
          />
          <div className="w-100">
            <Animate animate="left">
              <form className="row" onSubmit={contactUs}>
                <h2 className="general-title mb-4">
                  {generalDataGlobal[0].contact_data.form_title}
                </h2>
                <div className="col-lg-6">
                  <div className="pb-4">
                    <input
                      className="form w-100"
                      type="text"
                      value={name}
                      placeholder={generalDataGlobal[0].contact_data.name_placeholder}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="pb-4">
                    <input
                      className="form w-100"
                      type="number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder={generalDataGlobal[0].contact_data.phone_placeholder}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pb-4">
                  <div className="pb-4">
                    <input
                      className="form w-100"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={generalDataGlobal[0].contact_data.email_placeholder}
                    />
                  </div>
                  <div className="">
                    <input
                      type="file"
                      id="actual-btn"
                      name="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      hidden
                    />
                    <label className="form  attach w-100" for="actual-btn">
                      {file.name
                        ? file.name
                        : generalDataGlobal[0].contact_data.file_placeholder}
                    </label>
                  </div>
                </div>
                <div className="col-lg-12 pb-4">
                  <div className="pb-4 ">
                    <input
                      className="form w-100"
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      placeholder={generalDataGlobal[0].contact_data.reason_placeholder}
                    />
                  </div>
                  <div className="pb-4 ">
                    <input
                      className="form w-100"
                      type="text"
                      value={expectations}
                      onChange={(e) => setExpectations(e.target.value)}
                      placeholder={
                        generalDataGlobal[0].contact_data.expeactations_placeholder
                      }
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <button
                    type="submit"
                    className="button black-button send"
                  // onClick={(e) => {
                  //   // setSuccessPopup(true);
                  //   // e.preventDefault();
                  //   contactUs();
                  // }}
                  >
                    {generalDataGlobal[0].contact_data.button}
                  </button>
                </div>
              </form>
            </Animate>
          </div>
        </div>



        <div className="events-container-right fb-feed-container d-flex flex-column justify-content-center ">
          <div className="add-scrollbar adjust-events pb-5">

            <h3 className="mb-lg-4  px-lg-3 fb-feed-title">{generalDataGlobal[0].home_settings.facebook_title}</h3>
            <div className="">
              <div className="row justify-content-center ">
                {fbFeed?.map((post, index) =>
                  index < 3 ? (
                    <div
                      key={index}
                      className="col-lg-4 col-md-4 col-sm-6 col-12 fb-feed-col"
                    >
                      <div class="position-relative h-100">
                        <div class=" events-home-ratio fb-feed-no-pad shadow h-100">

                          <div className="d-flex flex-column h-100">
                            <img
                              className="fb-feed-img"
                              src={post.full_picture}
                              alt="test event 2"
                            />
                            <div className="d-flex flex-column justify-content-center p-2 flex-grow-1 align-items-center">
                              <div>
                                <p>
                                  {new Date(post.created_time).toDateString()}
                                </p>
                              </div>

                              {post.message ? (
                                <div>
                                  <p>
                                    {/* {(new Date(post.created_time)).toDateString()} */}
                                    {post.message}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <a href={post.permalink_url} target="_blank" rel="noreferrer">

                            <div class="overlay"></div>
                          </a>
                        </div>
                      </div>
                    </div>

                  ) : null,


                )}
                <div className="pt-lg-5 pt-4 text-md-start text-center d-md-block">
                  <a href={generalDataGlobal[0].home_settings.facebook_link} target="_blank" rel="noreferrer">
                    <button className="button black-button">
                      {generalDataGlobal[0].home_settings.facebook_title}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popup
          isOpen={successPopup}
          close={() => setSuccessPopup(false)}
          modalWindowClass="col-lg-5 col-md-9 col-sm-10 col-11"
        >
          <ContactPopup
            image={generalDataGlobal[0]?.contact_data.success_image}
            title="Success"
            subtitle={generalDataGlobal[0].contact_data.success_message}
          />
        </Popup>

        <Popup
          isOpen={errorPopup}
          close={() => setErrorPopup(false)}
          modalWindowClass="col-lg-5 col-md-9 col-sm-10 col-11"
        >
          <ContactPopup
            image={generalDataGlobal[0]?.contact_data.error_image}
            title="Error"
            subtitle="Sorry Something Went Wrong !"
            button="TRY AGAIN"
            errors={errorMessages}
          />
        </Popup>
      </Layout>
    </>
  );
}
