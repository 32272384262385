import Event from "../components/Event";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import Animate from "../components/Animate";
import { useContext, useState, useEffect } from "react";
import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";

export default function Events() {
  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [generalDataApiOptions1, setGeneralDataApiOptions1] = useState({});
  const [prevCount, setPrevCount] = useState();
  const [upcomingCount, setUpcomingCount] = useState();

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [pageUpcoming, setPageUpcoming] = useState(1);
  const [pagePrevious, setPagePrevious] = useState(1);
  const [lastPageUpcoming, setLastPageUpcoming] = useState();
  const [lastPagePrevious, setLastPagePrevious] = useState();

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "upcoming-events?page=" + pageUpcoming,
      callback: (res) => {
        let data = [...upcomingEvents, ...res.data.data];
        setLastPageUpcoming(res.data.last_page);
        setUpcomingEvents(data);
        setUpcomingCount(res.data.data.length);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUpcoming]);

  useEffect(() => {
    setGeneralDataApiOptions1({
      url: "previous-events?page=" + pagePrevious,
      callback: (res) => {
        let data1 = [...previousEvents, ...res.data.data];
        setLastPagePrevious(res.data.last_page);
        setPreviousEvents(data1);
        setPrevCount(res.data.data.length);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePrevious]);

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={generalDataApiOptions1} />

      <Layout activePage="events" blackNav={true}>
        <Helmet>
          <title>{generalDataGlobal[0]?.seo_pages.events.title}</title>
          <meta
            name="title"
            content={generalDataGlobal[0]?.seo_pages.events.title}
          />
          <meta
            name="description"
            content={generalDataGlobal[0]?.seo_pages.events.description}
          />
          <meta
            property="og:title"
            content={generalDataGlobal[0]?.seo_pages.events.title}
          />
          <meta
            property="og:description"
            content={generalDataGlobal[0]?.seo_pages.events.description}
          />
          <meta
            property="og:image"
            content={generalDataGlobal[0]?.seo_pages.events.image}
          />
          <meta
            property="twitter:title"
            content={generalDataGlobal[0]?.seo_pages.events.title}
          />
          <meta
            property="twitter:description"
            content={generalDataGlobal[0]?.seo_pages.events.description}
          />
          <meta
            property="twitter:image"
            content={generalDataGlobal[0]?.seo_pages.events.image}
          />
        </Helmet>
        <Banner
          blackNav={true}
          title={generalDataGlobal[0].events_settings.title}
          banner={generalDataGlobal[0].events_settings.image}
        />

        <div className="e-library custom-about-container d-lg-flex align-items-center d-flex position-relative py-5">
          <img
            className="event-bg"
            src="/assets/images/fixed-images/event-bg.svg"
            alt="background"
          />
          <Animate animate="left">
            <p>{generalDataGlobal[0].events_settings.description}</p>
          </Animate>

        </div>
        {upcomingCount > 0 ? (
          <h1 className="events-titles d-lg-block d-none text-center">
            {generalDataGlobal[0].events_settings.upcoming_events}
          </h1>
        ) : null}

        <div className="e-library custom-about-container d-lg-none d-block align-items-center d-flex position-relative justify-content-center">
          {upcomingCount > 0 ? (
            <h1 className="events-titles d-lg-none d-block">
              {generalDataGlobal[0].events_settings.upcoming_events}
            </h1>
          ) : null}
        </div>

        <div className="container-fluid justify-content-center mobile-direction-articles w-auto d-lg-flex">
          {upcomingEvents !== undefined
            ? upcomingEvents.map((event, index) => (
              <Event
                link={"/upcoming-events/" + event.slug}
                bg="/assets/images/fixed-images/lune.svg"
                classBg="lune-bg"
                slug={event.slug}
                key={index}
                id={event.id}
                index={index}
                title={event.title}
                image={event.full_path_image}
                date={event.date_formatted}
                paragraph={event.small_text}
                button={generalDataGlobal[0].events_settings.view_event}
                page="events"
              />
            ))
            : null}
        </div>

        <div className="custom-about-container  d-lg-none d-block">
          <div className=" py-5 text-center w-100">
            {pageUpcoming !== lastPageUpcoming ? (
              <p
                className="load-more events"
                onClick={() => setPageUpcoming(pageUpcoming + 1)}
              >
                {generalDataGlobal[0].events_settings.load_more}
              </p>
            ) : null}
          </div>
        </div>
        {pageUpcoming === lastPageUpcoming ? (
          <div
            className="black justify-content-center px-5 d-lg-flex d-none align-items-center  position-relative py-5"
          >
            <img
              className="event-bg"
              src="/assets/images/fixed-images/prev-event.svg"
              alt="background"
            />
            {pageUpcoming !== lastPageUpcoming ? (
              <p
                className="load-more events"
                onClick={() => setPageUpcoming(pageUpcoming + 1)}
              >
                {generalDataGlobal[0].events_settings.load_more}
              </p>
            ) : null}

            {prevCount > 0 ? (
              <h1 className="events-titles">
                {generalDataGlobal[0].events_settings.previous_events}
              </h1>
            ) : null}
          </div>
        ) : (
          <div className="black justify-content-center px-5 d-lg-flex d-none align-items-center  position-relative py-5">
            <img
              className="event-bg"
              src="/assets/images/fixed-images/prev-event.svg"
              alt="background"
            />
            {pageUpcoming !== lastPageUpcoming ? (
              <p
                className="load-more events"
                onClick={() => setPageUpcoming(pageUpcoming + 1)}
              >
                {generalDataGlobal[0].events_settings.load_more}
              </p>
            ) : null}

            {prevCount > 0 ? (
              <h1 className="events-titles d-lg-block d-none">
                {generalDataGlobal[0].events_settings.previous_events}
              </h1>
            ) : null}
          </div>
        )}

        <div className="d-lg-none d-block e-library custom-about-container align-items-center d-flex position-relative justify-content-center">
          {prevCount > 0 ? (
            <h1 className="events-titles d-lg-none d-block">
              {generalDataGlobal[0].events_settings.previous_events}
            </h1>
          ) : null}
        </div>

        <div className="container-fluid justify-content-center mobile-direction-articles w-auto d-lg-flex">
          {previousEvents !== undefined
            ? previousEvents.map((event, index) => (
              <Event
                key={index}
                link={"/previous-events/" + event.slug}
                bg="/assets/images/fixed-images/star-bg.svg"
                classBg="lune-bg"
                id={event.id}
                slug={event.slug}
                index={index}
                title={event.title}
                image={event.full_path_image}
                date={event.date_formatted}
                paragraph={event.small_text}
                button={generalDataGlobal[0].events_settings.view_event}
                page="events"
              />
            ))
            : null}

        </div>

        <div className="custom-about-container  d-lg-none d-block">
          <div className="py-5 text-center w-100">
            {pagePrevious !== lastPagePrevious ? (
              <p
                className="load-more events"
                onClick={() => setPagePrevious(pagePrevious + 1)}
              >
                {generalDataGlobal[0].events_settings.load_more}
              </p>
            ) : null}
          </div>
        </div>

        {pagePrevious !== lastPagePrevious ? (
          <div className="black justify-content-center px-5 d-lg-flex d-none align-items-center  position-relative py-5 overflow-hidden ">
            <p
              className="load-more events"
              onClick={() => setPagePrevious(pagePrevious + 1)}
            >
              {generalDataGlobal[0].events_settings.load_more}
            </p>
          </div>
        ) : null}
      </Layout>
    </>
  );
}
