export default function EventsHome(props) {
    return (

        <div className="all-events ">
            <p className="mb-lg-4 mb-3">{props.title}</p>
            <div className="position-relative">
                <div className="ratio events-home-ratio shadow">
                    <img src={props.image} alt={props.title} />
                </div>
                <div className="border-on-image-bottom-left-event"></div>
                <div className="overlay"></div>
            </div>
            <p className="event-date pt-lg-4 pt-2 mb-0">{props.date}</p>
        </div>
    )
}