import { useEffect } from "react"

export default function Popup(props) {

    // useEffect(() => {
    //     if (props.isOpen) document.querySelector('.scroll-container').classList.add('overflow-hidden');
    //     else document.querySelector('.scroll-container').classList.remove('overflow-hidden');
    // }, [props.isOpen]);


    useEffect(() => {
        document.querySelector('body').style.overflow = props.isOpen ? 'hidden' : '';
        document.querySelector('html').style.overflow = props.isOpen ? 'hidden' : '';
    }, [props.isOpen]);


    return (
        <div className={"popup" + (props.isOpen ? " " : " fade-out")}>
            <div className="row h-100 align-items-center justify-content-center">
                <div className={props.modalWindowClass}>
                    <div className="modal-window position-relative">
                        <div className=" close-popup text-end pb-3" onClick={props.close}>
                            <img className="" src="/assets/images/fixed-images/close-side-menu.svg" alt="close" />
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}