import { useContext, useState, useEffect } from "react";
import Animate from "../components/Animate";
import Article from "../components/Article";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";

export default function ELibrary() {
  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "e-library?page=" + page,
      method: "post",
      data: {
        e_library_categories_id: filter,
      },
      callback: (res) => {
        let data = [...pageData, ...res.data.data];
        setLastPage(res.data.last_page);
        setPageData(data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Helmet>
        <title>{generalDataGlobal[0]?.seo_pages['e-library'].title}</title>
        <meta
          name="title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          name="description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="og:title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          property="og:description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="og:image"
          content={generalDataGlobal[0]?.seo_pages['e-library'].image}
        />
        <meta
          property="twitter:title"
          content={generalDataGlobal[0]?.seo_pages['e-library'].title}
        />
        <meta
          property="twitter:description"
          content={generalDataGlobal[0]?.seo_pages['e-library'].description}
        />
        <meta
          property="twitter:image"
          content={generalDataGlobal[0]?.seo_pages['e-library'].image}
        />
      </Helmet>
      <Layout activePage="e-library" blackNav={false}>
        <Banner
          activePage="e-library"
          blackNav={false}
          title={generalDataGlobal[0].e_library_settings.title}
          banner={generalDataGlobal[0].e_library_settings.banner_image}
        />

        <div className="e-library custom-about-container d-lg-flex align-items-center d-flex position-relative py-5  ">
          <div className="row add-scrollbar">
            <div className="col">
              <Animate animate="left">
                <div
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].e_library_settings.description,
                  }}
                ></div>
              </Animate>
            </div>
          </div>
          {/* <div className="d-none">
            <div className="filter-articles-books">
              <h5
                className={filter === 0 ? "filter active mb-4 mx-2" : "filter mb-4 mx-2"}
                onClick={() => {
                  setFilter(0);
                  setPage(1);
                  setPageData([]);
                }}
              >
                All
              </h5>
              {generalDataGlobal[0].e_library_categories
                ? generalDataGlobal[0].e_library_categories.map(
                    (category, index) => (
                      <h5
                        key={index}
                        className={
                          
                          category.id === filter
                            ? "filter active mb-4 mx-2"
                            : "filter mb-4 mx-2"
                            
                        }
                        onClick={() => {
                          setFilter(category.id);
                          setPage(1);
                          setPageData([]);
                        }}
                      >
                        {category.title}
                      </h5>
                    )
                  )
                : null}
            </div>
          </div> */}
        </div>

        <div className="custom-about-container d-flex ">
          <div className="filter-articles-books">
            <h5
              className={filter === 0 ? "filter active mb-4 mx-2" : "filter mb-4 mx-2"}
              onClick={() => {
                setFilter(0);
                setPage(1);
                setPageData([]);
              }}
            >
              All
            </h5>
            {generalDataGlobal[0].e_library_categories
              ? generalDataGlobal[0].e_library_categories.map(
                (category, index) => (
                  <h5
                    key={index}
                    className={
                      category.id === filter
                        ? "filter active mb-4 me-2"
                        : "filter mb-4 me-2"
                    }
                    onClick={() => {
                      setFilter(category.id);
                      setPage(1);
                      setPageData([]);
                    }}
                  >
                    {category.title}
                  </h5>
                )
              )
              : null}
          </div>
        </div>

        <div className="container-fluid justify-content-center mobile-direction-articles w-auto d-lg-flex">

          {pageData !== undefined
            ? pageData.map((article, index) => (
              <Article
                isArabic={article.isArabic}
                key={index}
                id={article.id}
                index={index}
                title={article.title}
                single_page={article.single_page}
                download_file={article.download_file}
                full_path_download_file={article.full_path_download_file}
                slug={article.slug}
                image={article.full_path_image}
                paragraph={article.small_text}
                button={
                  (article.single_page && article.download_file) ||
                    (article.single_page && !article.download_file)
                    ? generalDataGlobal[0].e_library_settings.read_more_button
                    : "Download"
                }
              />
            ))
            : null}
          {/* </div> */}

        </div>

        <div className="custom-about-container d-block">
          <div className="row py-5 text-center w-100 justify-content-center d-grid">
            {page !== lastPage ? (
              <p className="load-more" onClick={() => setPage(page + 1)}>
                {generalDataGlobal[0].e_library_settings.load_more_button}
              </p>
            ) : null}
          </div>
        </div>

        {/* <div className="load-more-section black justify-content-center px-5 d-lg-flex d-none align-items-center  position-relative py-5 ">
          {page !== lastPage ? (
            <p className="load-more" onClick={() => setPage(page + 1)}>
              {generalDataGlobal[0].e_library_settings.load_more_button}
            </p>
          ) : null}
        </div> */}
      </Layout>
    </>
  );
}
