import Stars from "./Stars";

export default function Banner(props) {
    return (
        <>
            <div className={"position-relative banner-background align-items-center d-lg-flex banner-position overflow-hidden" + (props.blackNav === true ? " white-background" : " black-background")}>
                <div className="title-banner">
                    {props.blackNav === true ?
                        <div className="background-banner-image ">
                            <img src="/assets/images/fixed-images/result.svg" alt="background" />
                        </div>
                        :
                        props.activePage !== "e-library" ?
                       <Stars />
                       : null
                    }
                    <h1 className="mb-0 py-5">{props.title}</h1>
                </div>
            </div>
            <div>
                <div className="banner-ratio">
                    <img src={props.banner} alt="banner" />
                </div>
            </div>
        </>
    )
}