import { memo } from "react";
const Stars = memo(() => {

    function renderStars() {
        var stars = [];
        for (let i = 0; i < 1000; i++) {
            stars.push((
                <div className=" animatestars  single-star" key={i} style={{
                    position: "absolute",
                    top: Math.floor(Math.random() * 101) + "%",
                    left: Math.floor(Math.random() * 101) + "%",
                    opacity: "0." + Math.floor(Math.random() * 101),
                    transform: "translateY(" + Math.floor(Math.random() * 101) + ") scale(0." + Math.floor(Math.random() * 101) + ")"
                }}></div>
            ));
        }
        return stars;
    }

    return (
        <>
            <div className="stars" id="starscontainer">
                {renderStars()}
            </div >
        </>
    )
})

export default Stars;